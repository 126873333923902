import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Col from "react-bootstrap/Col";
import ReactMarkdown from "react-markdown";
import Title from "../components/Title";
import styled from "styled-components";
import Gallery from "../components/Gallery";

const ContentCol = styled(Col)`
  padding-right: 1rem !important;
`;

// markup
const IndexPage = () => {
  const data = useStaticQuery(query);
  const images = data.strapiStronaGlowna.Zdjecia.map(
    ({ localFile }) => localFile.childImageSharp
  );
  return (
    <Layout pageTitle="Willa pod Dębami">
      <ContentCol md={8}>
        <div>
          <Title>Opis pensjonatu</Title>
          <ReactMarkdown>{data.strapiStronaGlowna.Opis}</ReactMarkdown>
        </div>
        <div>
          <Title>Galeria</Title>
          <Gallery images={images} />
        </div>
      </ContentCol>
      <ContentCol md={4}>
        <Title>Wyposażenie apartamentów</Title>
        <ReactMarkdown>{data.strapiStronaGlowna.Wyposazenie}</ReactMarkdown>
      </ContentCol>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiStronaGlowna {
      Wyposazenie
      Opis
      Zdjecia {
        name
        width
        height
        localFile {
          childImageSharp {
            fullsize: gatsbyImageData(layout: FULL_WIDTH)
            thumbnail: gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              aspectRatio: 1
              width: 200
              quality: 50
            )
          }
        }
      }
    }
  }
`;

export default IndexPage;
